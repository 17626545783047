import Loader from 'components/common/Loader/Loader';
import { API_URL_COINCAP, API_URL_SOCKET, COINS_LIMIT } from 'constants.js';
import filter from 'lodash/filter';
import { TOAST_ERROR_DEFAULT_OPTIONS } from 'components/common/toast';
import orderByFunc from 'lodash/orderBy';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'reactstrap';
import { scrollToTop } from 'utils.js';
import CurrencySelector from './CurrencySelector';
import TrackerFilter from './TrackerFilter';
import TrackerHeader from './TrackerHeader';
import TrackerList from './TrackerList';
import { formatDataValues, mapCoinProps } from './utils';
import { useContext } from 'react';
import { ThemeContext } from 'components/theme/Theme';
import './Tracker.css';

const TrackerWrapper = () => {
  const pricesWs = useRef(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const dataMounted = useRef();
  const [currency, setCurrency] = useState({ key: 'USD', coef: 1 });
  const [orderBy, setOrderBy] = useState({ field: null, order: 'asc' });
  const { formatMessage } = useIntl();
  const { theme } = useContext(ThemeContext);

  const updateTrades = (updates) => {
    setData((prevData) => {
      if (!prevData?.length) return;

      const dataMap = new Map();
      prevData.forEach((item) => {
        if (item.id) {
          dataMap.set(item.id, item);
        }
      });

      Object.keys(updates).forEach((key) => {
        if (dataMap.has(key)) {
          const prevItem = dataMap.get(key);
          dataMap.set(
            key,
            mapCoinProps({
              ...prevItem,
              priceUsd: updates[key],
            })
          );
        }
      });

      const updatedData = orderByFunc(
        [...dataMap].map(([_, value]) => value),
        'orderIndex',
        'asc'
      );

      return updatedData;
    });
  };

  const updateTradesPrices = useCallback((prices) => {
    updateTrades(JSON.parse(prices));
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${API_URL_COINCAP}/assets?limit=${COINS_LIMIT}`
      );
      const { data } = await response.json();
      const formattedData = formatDataValues(data);
      setData(formattedData);
      setDataOriginal(formattedData);
      if (formattedData?.length) {
        dataMounted.current = true;
      }
    } catch (error) {
      toast.error(
        `${formatMessage({ id: 'error.request-failed' })} ${error.message}`,
        {
          ...TOAST_ERROR_DEFAULT_OPTIONS,
          theme,
        }
      );

      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!pricesWs?.current) {
      pricesWs.current = new WebSocket(`${API_URL_SOCKET}/prices?assets=ALL`);
    }

    pricesWs.current.onmessage = (msg) => {
      updateTradesPrices(msg.data);
    };

    pricesWs.current.onerror = (error) => {
      toast.error(
        `${formatMessage({ id: 'error.socket-failed' })} ${error.message}`,
        {
          ...TOAST_ERROR_DEFAULT_OPTIONS,
          theme,
        }
      );
    };

    return () => {
      if (pricesWs?.current) {
        pricesWs.current.close();
      }
    };
  }, [updateTradesPrices]);

  const onChangeSort = (mode) => {
    const order = orderBy.order === 'asc' ? 'desc' : 'asc';
    const sortedData = orderByFunc(data, mode, order)
      .map((item, index) => ({
        ...item,
        orderIndex: index,
      }))
      .filter((coin) => coin?.id);

    setData(sortedData);
    setOrderBy({ field: mode, order });
    scrollToTop();
  };

  const onChangeCurrency = (currency) => {
    setCurrency(currency);
  };

  const onChageSearch = (query) => {
    const searchQuery = query ? query.toLowerCase() : '';
    let filteredData = filter(
      dataOriginal,
      (item) => item.name && item.name.toLowerCase().indexOf(searchQuery) >= 0
    );

    if (orderBy && orderBy.field) {
      filteredData = orderByFunc(filteredData, orderBy.field, orderBy.order);
    }

    setData(filteredData);
    scrollToTop();
  };

  return (
    <div className="loader-wrapper app-h">
      {!loading ? (
        <>
          <div className="tracker-sticky-header">
            <Container>
              <Row className="mb-1">
                <Col xs={12} sm={4} lg={3}>
                  <TrackerFilter onChageSearch={onChageSearch} />
                </Col>
                <Col>
                  <CurrencySelector onChange={onChangeCurrency} />
                </Col>
              </Row>

              <TrackerHeader orderBy={orderBy} onChangeSort={onChangeSort} />
            </Container>
          </div>
          <Container className="tracker-wrapper">
            <TrackerList list={data} currency={currency} />
          </Container>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TrackerWrapper;

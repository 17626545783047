import { SYMBOL_EMPTY } from 'constants.js';
import { pickSvgUrl } from 'icons.js';
import isNumber from 'lodash/isNumber';
import { memo } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Exchange from '../Exchange';
import AnimateRow from './AnimateRow';
import { pickCap24hrChangeColor } from './utils';

const TrackerItem = (props) => {
  const {
    currency,
    priceUsd,
    id,
    symbol,
    name,
    marketCapUsd,
    volumeUsd24Hr,
    maxSupply,
    changePercent24Hr: changePercent24HrOriginal,
  } = props;
  const { formatNumber } = useIntl();

  const changePercent24Hr = pickCap24hrChangeColor(
    changePercent24HrOriginal,
    formatNumber
  );

  return (
    <Link
      className="app-table-row"
      to={{
        pathname: `/currency/${id}`,
      }}
      state={{ modal: true }}
      key={`link-${id}`}
    >
      <AnimateRow value={priceUsd} key={`anim-${id}`} />
      <div className="app-table-col app-table-col-size-name">
        <div className="d-flex align-items-center">
          <img
            src={pickSvgUrl(symbol)}
            alt={`Logo ${name}`}
            width={32}
            height={32}
            className="mr-2"
          />
          <div className="text-left">
            <div className="item-badge">
              <Badge color="secondary">{symbol}</Badge>
            </div>
            <div className="item-name">{name}</div>
          </div>
        </div>
      </div>
      <div className="app-table-col text-right app-table-col-size-mktcap">
        <span className="d-inline-block text-label d-sm-none mr-1">
          <FormattedMessage id="app.market-cap" />:
        </span>
        {formatNumber(marketCapUsd * currency.coef, {
          currency: currency.key,
          style: 'currency',
          currencyDisplay: 'symbol',
        })}
      </div>
      <div className="app-table-col text-right app-table-col-size-priceUsd">
        <span className="d-inline-block text-label d-sm-none mr-1">
          <FormattedMessage id="app.price" />:
        </span>
        {isNumber(priceUsd) && priceUsd !== 0
          ? formatNumber(priceUsd * currency.coef, {
              currency: currency.key,
              style: 'currency',
              currencyDisplay: 'symbol',
              maximumFractionDigits: 8,
            })
          : SYMBOL_EMPTY}
      </div>
      <div className="app-table-col text-right app-table-col-size-volumeUsd24Hr">
        <span className="d-inline-block text-label d-sm-none mr-1">
          <FormattedMessage id="app.volume24" />:
        </span>
        {isNumber(volumeUsd24Hr) ? (
          <FormattedNumber value={volumeUsd24Hr} />
        ) : (
          SYMBOL_EMPTY
        )}
      </div>
      <div className="app-table-col text-right app-table-col-size-maxSupply">
        <span className="d-inline-block text-label d-sm-none mr-1">
          <FormattedMessage id="app.circulating-supply" />:
        </span>
        {isNumber(maxSupply) ? (
          <FormattedNumber value={maxSupply} />
        ) : (
          SYMBOL_EMPTY
        )}
      </div>
      <div className="app-table-col text-right app-table-col-size-cap24hrChange">
        <span className="d-inline-block text-label d-sm-none mr-1">
          <FormattedMessage id="app.change24" />:
        </span>
        {changePercent24Hr}
      </div>
      <div className="app-table-col text-right app-table-col-size-buttons pr-0">
        <span className="d-inline-block text-label d-sm-none mr-1">
          <FormattedMessage id="app.trade" />:
        </span>
        <Exchange currency={symbol} type="link" />
      </div>
    </Link>
  );
};

export default memo(TrackerItem);

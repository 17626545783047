import { cloneElement, createContext, useEffect, useState } from 'react';

export const ThemeContext = createContext();

export const ThemeConsumer = (props) => {
  const { children } = props;
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return cloneElement(children, {
          context,
        });
      }}
    </ThemeContext.Consumer>
  );
};

const ThemeProvider = (props) => {
  const { children } = props;
  const [theme, setTheme] = useState(() => {
    const options = JSON.parse(localStorage.getItem('options'));
    return options && options.theme ? options.theme : 'light';
  });

  useEffect(() => {
    const options = JSON.stringify({ theme });
    localStorage.setItem('options', options);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

import { ThemeContext } from 'components/theme/Theme';
import { useContext } from 'react';

const Layout = (props) => {
  const { children } = props;
  const { theme } = useContext(ThemeContext);

  return <div className={`app-main theme-${theme}`}>{children}</div>;
};

export default Layout;

import Loader from 'components/common/Loader/Loader';
import TrackerItemFull from 'components/tracker/TrackerItemFull';
import { useParams } from 'react-router-dom';

const CurrencySingle = () => {
  const { code } = useParams();

  return code ? <TrackerItemFull id={code} /> : <Loader />;
};

export default CurrencySingle;

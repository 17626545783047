import { ROUTE_CURRENCY_SINGLE, ROUTE_NOT_FOUND } from 'components/RootRoutes';
import Home from 'pages/home/Home';
import NotFound from 'pages/not-found/NotFound';
import { useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CurrencySingle from './CurrencySingle';
import CurrencySingleModal from './CurrencySingleModal';

const CurrencySingleSwitcher = () => {
  const location = useLocation();
  const previousLocation = useRef(location); // Using ref to track previous location

  useEffect(() => {
    // Set previousLocation if props.location is not modal and navigation is not "POP"
    if (!location.state || !location?.state?.modal) {
      previousLocation.current = location;
    }
  }, [location]);

  const isModal = !!(
    location.state &&
    location.state.modal &&
    previousLocation.current !== location
  );

  return (
    <>
      <Routes location={isModal ? previousLocation.current : location}>
        <Route path="/" element={<Home />} />
        <Route path={ROUTE_CURRENCY_SINGLE} element={<CurrencySingle />} />
        <Route path={ROUTE_NOT_FOUND} element={<NotFound />} />
        <Route path="*" element={<Navigate to={ROUTE_NOT_FOUND} />} />
      </Routes>

      {isModal && (
        <Routes>
          <Route
            path={ROUTE_CURRENCY_SINGLE}
            element={<CurrencySingleModal />}
          />
        </Routes>
      )}
    </>
  );
};

export default CurrencySingleSwitcher;

import Helmet from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Container } from 'reactstrap';

const NotFound = () => {
  return (
    <>
      <Helmet title="Page Not Found" />
      <Container className="text-center">
        <h3>
          404 | <FormattedMessage id="app.page-not-found" />
        </h3>
      </Container>
    </>
  );
};

export default NotFound;

import TrackerWrapper from 'components/tracker/TrackerWrapper';
import PageMeta from './PageMeta';

const Home = () => {
  return (
    <>
      <PageMeta />
      <TrackerWrapper />
    </>
  );
};

export default Home;

import Switch from '@mui/material/Switch';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ThemeContext } from './Theme';

const ThemeSwitcher = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const [checked, setChecked] = useState(theme === 'dark');

  useEffect(() => {
    if (theme === 'dark') {
      setChecked(true);
    }
  }, [theme]);

  const onChange = (event) => {
    const isChecked = event.target.checked;
    setTheme(isChecked ? 'dark' : 'light');
    setChecked(isChecked);
  };

  return (
    <>
      <div className="font-xs d-none d-sm-inline-block">
        <FormattedMessage id="app.view.day" />
      </div>
      <Switch color="primary" onChange={onChange} checked={checked} />
      <div className="font-xs d-none d-sm-inline-block">
        <FormattedMessage id="app.view.night" />
      </div>
    </>
  );
};

export default ThemeSwitcher;

import {
  CHANGELLY_EXANGE_AMOUNT,
  CHANGELLY_EXANGE_URL,
  CHANGELLY_REF_ID,
} from 'constants.js';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

const Exchange = (props) => {
  const { currency, type } = props;
  const exhangeHandler = (e, currency, direction = 'buy') => {
    e.preventDefault();
    e.stopPropagation();
    currency = currency !== 'BTC' ? currency.toLowerCase() : 'eth';
    const exchangeCurrenciesUrl =
      direction === 'buy'
        ? `from=btc&to=${currency}`
        : `from=${currency}&to=btc`;
    const url = `${CHANGELLY_EXANGE_URL}/?${exchangeCurrenciesUrl}&ref_id=${CHANGELLY_REF_ID}&amount=${CHANGELLY_EXANGE_AMOUNT}`;

    window.open(url, '_blank');
  };

  return type === 'link' ? (
    <>
      <Button
        onClick={(e) => exhangeHandler(e, currency, 'buy')}
        size="sm"
        color="link"
      >
        <FormattedMessage id="app.buy" />
      </Button>
      {type === 'link' ? <span className="divider">/</span> : null}
      <Button
        onClick={(e) => exhangeHandler(e, currency, 'sell')}
        size="sm"
        color="link"
      >
        <FormattedMessage id="app.sell" />
      </Button>
    </>
  ) : (
    <>
      <Button
        onClick={(e) => exhangeHandler(e, currency, 'buy')}
        color="success"
        className="mr-3"
      >
        <FormattedMessage id="app.buy" />
      </Button>
      <Button
        onClick={(e) => exhangeHandler(e, currency, 'sell')}
        color="danger"
      >
        <FormattedMessage id="app.sell" />
      </Button>
    </>
  );
};

export default Exchange;

import Loop from '@mui/icons-material/Loop';
import { FormattedMessage } from 'react-intl';
import './Loader.css';

const Loader = (props) => {
  const { isText = true } = props;
  return (
    <>
      <div className="overlay"></div>
      <div className="text-primary text-center loader">
        <Loop className="anim-spinner" />
        {isText ? (
          <div>
            <FormattedMessage id="app.loading" />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Loader;

import ReactDOM from 'react-dom/client'; // Notice the change here
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import messagesEn from './translations/en.json';

const rootElement = document.getElementById('root');

const root = ReactDOM.createRoot(rootElement);

const messages = {
  en: messagesEn,
};

if (window.self === window.top) {
  root.render(
    <IntlProvider locale="en" messages={messages['en']}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </IntlProvider>
  );
}

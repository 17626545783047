import Loader from 'components/common/Loader/Loader';
import { TOAST_ERROR_DEFAULT_OPTIONS } from 'components/common/toast';
import { ThemeContext } from 'components/theme/Theme';
import { API_URL_COINCAP } from 'constants.js';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { appedUrlParams } from 'utils.js';
import Chart from './Chart';
import { CHART_DATES } from './constants';

const ChartWrapper = (props) => {
  const { id } = props;
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(CHART_DATES[CHART_DATES.length - 1]);
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const { theme } = useContext(ThemeContext);

  const fetchChartData = async (item) => {
    setLoading(true);
    setSelected(item);
    const params = item.params();
    const url = appedUrlParams(
      `${API_URL_COINCAP}/assets/${id}/history`,
      params
    );
    try {
      const response = await fetch(url);
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      toast.error(
        `${formatMessage({ id: 'error.request-failed-chart' })} ${
          error.message
        }`,
        {
          ...TOAST_ERROR_DEFAULT_OPTIONS,
          theme,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData(selected);
  }, []);

  return (
    <>
      <div className="loader-wrapper mb-3">
        <div className="clearfix">
          <Nav pills className="mb-2 float-right">
            {CHART_DATES.map((navItem) => (
              <NavItem key={navItem.key}>
                <NavLink
                  href="#"
                  active={navItem.key === selected.key}
                  onClick={() => fetchChartData(navItem)}
                >
                  {navItem.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>

        <Chart data={data} selected={selected} loading={loading} />

        {loading ? <Loader /> : null}
      </div>
    </>
  );
};

export default ChartWrapper;

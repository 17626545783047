import Modal from 'components/Modal/Modal';
import Loader from 'components/common/Loader/Loader';
import TrackerItemFull from 'components/tracker/TrackerItemFull';
import { useNavigate, useParams } from 'react-router-dom';

const CurrencySingleModal = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  const back = () => {
    navigate(-1);
  };

  return (
    <Modal show={true} onToggle={back}>
      {code ? <TrackerItemFull id={code} /> : <Loader />}
    </Modal>
  );
};

export default CurrencySingleModal;

import ChartWrapper from 'components/chart/ChartWrapper';
import Loader from 'components/common/Loader/Loader';
import { TOAST_ERROR_DEFAULT_OPTIONS } from 'components/common/toast';
import { ThemeContext } from 'components/theme/Theme';
import { API_URL_COINCAP, SYMBOL_EMPTY } from 'constants.js';
import { pickSvgUrl } from 'icons.js';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import Exchange from '../Exchange';
import { ROUTE_HOME } from '../RootRoutes';

const TrackerItemFull = (props) => {
  const { id } = props;
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const { formatNumber } = useIntl();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      try {
        const resp = await fetch(`${API_URL_COINCAP}/assets/${id}`);
        const { data } = await resp.json();
        if (data && data.name) {
          setItem(data);
        } else {
          navigate(ROUTE_HOME);
        }
      } catch (error) {
        toast.error(
          `${formatMessage({ id: 'error.request-failed' })} ${error.message}`,
          {
            ...TOAST_ERROR_DEFAULT_OPTIONS,
            theme,
          }
        );
        navigate(ROUTE_HOME);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const marketCapUsd = parseFloat(item?.marketCapUsd);
  const volumeUsd24Hr = parseFloat(item?.volumeUsd24Hr);

  return (
    <Container className="full-item loader-wrapper py-sm-2">
      {loading ? (
        <Loader />
      ) : item ? (
        <>
          <Helmet title={item.name} />

          <div className="d-flex align-items-center mb-3">
            <div className="mr-5">
              <img
                className="mb-3 logo-img md"
                src={pickSvgUrl(item.symbol)}
                alt={`Logo ${item.name}`}
              />
            </div>
            <div className="py-2">
              <h1 className="pt-0 mt-0">{item.name}</h1>
              <h4>
                <FormattedNumber value={item.supply} />
                &nbsp;
                <small>
                  <FormattedMessage id="app.сirculating-btc-supply" />
                </small>
              </h4>
            </div>
          </div>

          <Row className="mb-3 cards">
            <Col xs={12} sm={4} className="mb-2">
              <Card>
                <CardBody>
                  <CardTitle>
                    <FormattedMessage id="app.current-value" />
                  </CardTitle>
                  <CardText>
                    <FormattedNumber value={item.priceUsd} />
                    &nbsp;(
                    {formatNumber(item.changePercent24Hr / 100, {
                      style: 'percent',
                    })}
                    )
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={4} className="mb-2">
              <Card>
                <CardBody>
                  <CardTitle>
                    <FormattedMessage id="app.market-cap" />
                  </CardTitle>
                  <CardText>
                    {isNumber(marketCapUsd) && !isNaN(marketCapUsd) ? (
                      <FormattedNumber value={marketCapUsd} />
                    ) : (
                      SYMBOL_EMPTY
                    )}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={4} className="mb-2">
              <Card>
                <CardBody>
                  <CardTitle>
                    <FormattedMessage id="app.24htVoume" />
                  </CardTitle>
                  <CardText>
                    {isNumber(volumeUsd24Hr) && !isNaN(volumeUsd24Hr) ? (
                      <FormattedNumber value={volumeUsd24Hr} />
                    ) : (
                      SYMBOL_EMPTY
                    )}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ChartWrapper id={item.id} />

          <div className="text-right item-footer">
            <Exchange currency={item.symbol.toLowerCase()} type="primary" />
          </div>
        </>
      ) : null}
    </Container>
  );
};

export default TrackerItemFull;

import Search from '@mui/icons-material/Search';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormGroup, Input } from 'reactstrap';

const TrackerFilter = (props) => {
  const { onChageSearch } = props;
  const [input, setInput] = useState('');
  const { formatMessage } = useIntl();

  const handleChange = (e) => {
    setInput(e.target.value);
    onChageSearch(e.target.value);
  };

  return (
    <FormGroup className="input-icon">
      <Input
        value={input}
        placeholder={formatMessage({ id: 'search.byName' })}
        onChange={handleChange}
      />
      <Search className="icon" />
    </FormGroup>
  );
};

export default TrackerFilter;

import { APP_NAME } from 'constants.js';
import './Footer.css';
import { Container } from 'reactstrap';

const Footer = () => {
  return (
    <footer className="text-center app-footer">
      <Container>
        <div className="d-flex justify-content-between">
          <div>
            &copy; {new Date().getFullYear()} - {APP_NAME}
          </div>
          <div className="ml-5">
            {' '}
            Developed by{' '}
            <a
              href="https://mzworks.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              mzworks.org
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;

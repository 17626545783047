import {
  AddShoppingCart,
  Close,
  MailOutline,
  Menu,
  Timeline,
} from '@mui/icons-material';
import { ROUTE_HOME } from 'components/RootRoutes';
import ThemeSwitcher from 'components/theme/ThemeSwitcher';
import { APP_MAIL, APP_NAME } from 'constants.js';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { Container, Navbar } from 'reactstrap';
import './Header.css';

const DURATION = 150;

const Header = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Navbar className="app-header" fixed="top" light>
        <Container>
          <Link to={ROUTE_HOME} className="mr-auto navbar-brand">
            <div className="logo">
              <Timeline /> {APP_NAME}
            </div>
          </Link>
          <a
            className="btn btn-sm mx-4 btn-primary d-none d-lg-block"
            href="https://1.envato.market/XEqRy"
          >
            <AddShoppingCart />
            &nbsp;
            <FormattedMessage id="app.buy-now" defaultMessage="Buy Script" />
          </a>
          <ThemeSwitcher />

          {!collapsed ? (
            <Menu onClick={toggleNavbar} className="mr-2 ml-3 ml-sm-5" />
          ) : (
            <Close onClick={toggleNavbar} className="mr-2 ml-3 ml-sm-5" />
          )}
        </Container>
      </Navbar>

      <Transition in={collapsed} timeout={DURATION}>
        {(state) => (
          <div className={`anim-bg anim-nav anim-nav-${state}`}>
            <Container className="text-right">
              <nav>
                <a
                  className="btn btn-sm mx-4 btn-primary d-lg-none"
                  href="https://1.envato.market/XEqRy"
                >
                  <AddShoppingCart />
                  &nbsp;
                  <FormattedMessage
                    id="app.buy-now"
                    defaultMessage="Buy Script"
                  />
                </a>
                <a href={`mailto:${APP_MAIL}`} title="Mail Us">
                  <MailOutline />
                  &nbsp;
                  <FormattedMessage id="app.mail-us" />
                </a>
              </nav>
            </Container>
          </div>
        )}
      </Transition>
    </>
  );
};

export default Header;

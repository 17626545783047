import RootRoutes from 'components/RootRoutes';
import Footer from 'components/common/Footer/Footer';
import Header from 'components/common/Header/Header';
import Layout from 'components/common/Layout';
import ThemeProvider from 'components/theme/Theme';
import { APP_TITLE_PREFFIX } from 'constants.js';
import Helmet from 'react-helmet';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './assets/bootstrap.css';
import './assets/main.css';

const App = () => {
  return (
    <ThemeProvider>
      <Layout>
        <ToastContainer />
        <Helmet titleTemplate={`%s | ${APP_TITLE_PREFFIX}`} />
        <Header />
        <RootRoutes />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default App;

import { Virtuoso } from 'react-virtuoso';
import TrackerItem from './TrackerItem';

const TrackerVirtualList = (props) => {
  const { items, currency } = props;
  return (
    <Virtuoso
      style={{ height: '100%' }}
      totalCount={items.length}
      itemContent={(index) => {
        const {
          priceUsd,
          id,
          symbol,
          name,
          marketCapUsd,
          volumeUsd24Hr,
          maxSupply,
          changePercent24Hr,
        } = items[index];
        return (
          <TrackerItem
            id={id}
            priceUsd={priceUsd}
            symbol={symbol}
            name={name}
            marketCapUsd={marketCapUsd}
            volumeUsd24Hr={volumeUsd24Hr}
            maxSupply={maxSupply}
            changePercent24Hr={changePercent24Hr}
            currency={currency}
          />
        );
      }}
    />
  );
};

export default TrackerVirtualList;

import Loader from 'components/common/Loader/Loader';
import { ThemeContext } from 'components/theme/Theme';
import { API_URL_COINCAP } from 'constants.js';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { TOAST_ERROR_DEFAULT_OPTIONS } from 'components/common/toast';

const customStyles = (theme) => {
  if (theme === 'dark') {
    const borderDarkColor = '#1a1a1a';
    const textColor = '#ffffff';
    const backgroundDarkColor = '#222222';

    return {
      control: (styles) => ({
        ...styles,
        margin: 0,
        padding: 0,
        minHeight: '34px',
        height: '34px',
        color: textColor,
        borderColor: borderDarkColor,
        backgroundColor: backgroundDarkColor,
        borderRadius: '2px',
        ':hover': {
          borderColor: backgroundDarkColor,
        },
      }),
      option: (styles) => ({
        ...styles,
        color: textColor,
        backgroundColor: backgroundDarkColor,
        ':hover': {
          backgroundColor: borderDarkColor,
        },
      }),
      menuList: (styles) => ({
        ...styles,
        backgroundColor: backgroundDarkColor,
      }),

      singleValue: (styles) => ({
        ...styles,
        margin: 0,
        padding: '0px 3px',
        minHeight: '20px',
        color: textColor,
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        margin: 0,
        padding: '2px 3px',
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: borderDarkColor,
      }),
    };
  }
  return {
    control: (styles) => ({
      ...styles,
      margin: 0,
      padding: 0,
      minHeight: '34px',
      height: '34px',
      color: 'red',
      borderRadius: '2px',
    }),

    singleValue: (styles) => ({
      ...styles,
      margin: 0,
      padding: '0px 3px',
      minHeight: '20px',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      margin: 0,
      padding: '2px 3px',
    }),
  };
};

const USD_CURRENCY = {
  id: 'american-usd',
  rateUsd: '1',
  symbol: 'USD',
};

const prepareCurrencies = (list = []) =>
  list.map(({ rateUsd, symbol: label }) => ({
    label,
    value: parseFloat(rateUsd),
  }));

const CurrencySelector = (props) => {
  const { onChange } = props;
  const [currenciesDisplay, setCurrenciesDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const { formatMessage } = useIntl();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await fetch(`${API_URL_COINCAP}/rates`);
        const data = await resp.json();
        const rates = prepareCurrencies([USD_CURRENCY, ...data.data]);
        setCurrenciesDisplay(rates);
        setSelectedOption(rates[0]);
      } catch (error) {
        toast.error(
          `${formatMessage({ id: 'error.request-failed' })} ${error.message}`,
          {
            ...TOAST_ERROR_DEFAULT_OPTIONS,
            theme,
          }
        );
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    const value = selectedOption?.value
      ? {
          key: selectedOption.label,
          coef: selectedOption.value,
        }
      : null;
    onChange(value);
  };

  return (
    <div className="currency-selector">
      {!loading ? (
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={currenciesDisplay}
          name="form-field-name"
          clearable={false}
          placeholder={formatMessage({ id: 'currency' })}
          styles={customStyles(theme)}
        />
      ) : (
        <Loader isText={false} />
      )}
    </div>
  );
};

export default CurrencySelector;

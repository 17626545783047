import { SYMBOL_EMPTY } from 'constants.js';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import isNull from 'lodash/isNull';

export const parseFloatNumber = (value) => {
  const floatValue = parseFloat(value);
  return !isNaN(floatValue) ? floatValue : '';
};

export const parseIntNumber = (value) => {
  const floatValue = parseInt(value, 10);
  return !isNaN(floatValue) ? floatValue : '';
};

export const pickCap24hrChangeColor = (val, formatNumber) => {
  if (!isNumber(val)) {
    return SYMBOL_EMPTY;
  } else if (val > 0) {
    return (
      <div className="d-inline-block text-success">
        <span className="d-inline-block font-xs app-arrow">&#9650;</span>
        {formatNumber(val / 100, {
          style: 'percent',
          maximumFractionDigits: 2,
        })}
      </div>
    );
  } else if (val < 0) {
    return (
      <div className="text-danger">
        <span className="font-xs app-arrow">&#9660;</span>
        {formatNumber(val / 100, {
          style: 'percent',
          maximumFractionDigits: 2,
        })}
      </div>
    );
  }
  return formatNumber(val / 100, {
    style: 'percent',
    maximumFractionDigits: 2,
  });
};

export const mapCoinProps = (item) => {
  const supply = parseFloatNumber(item.supply);
  const maxSupply =
    item.maxSupply && !isNull(item.supply)
      ? parseFloatNumber(item.maxSupply)
      : supply;
  const priceUsd = parseFloatNumber(item.priceUsd);
  const changePercent24Hr = parseFloatNumber(item.changePercent24Hr);
  const volumeUsd24Hr = parseFloatNumber(item.volumeUsd24Hr);
  const marketCapUsd = parseFloatNumber(item.marketCapUsd);
  const vwap24Hr = parseFloatNumber(item.vwap24Hr);
  const rank = parseIntNumber(item.rank);
  return {
    ...item,
    changePercent24Hr,
    marketCapUsd,
    maxSupply,
    priceUsd,
    rank,
    supply,
    volumeUsd24Hr,
    vwap24Hr,
  };
};

export const formatDataValues = (data = []) =>
  data.map(mapCoinProps).filter((coin) => coin?.id);

import { useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

const DURATION = 350;

const AnimateRow = (props) => {
  const { value: nextValue } = props;
  const [value, setValue] = useState(undefined);
  const [animation, setAnimation] = useState(null);
  const [show, setShow] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    let animate = animation;
    let showAnimation = false;

    if (value < nextValue) {
      showAnimation = true;
      animate = 'up';
      resetAnimation();
    } else if (value > nextValue) {
      animate = 'down';
      showAnimation = true;
      resetAnimation();
    }

    setValue(nextValue);
    setAnimation(animate);
    setShow(showAnimation);
    return () => clearTimeout(timerRef.current);
  }, [nextValue, value, animation]);

  const resetAnimation = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setShow(false);
    }, DURATION);
  };

  return (
    <Transition in={show} timeout={DURATION}>
      {(state) => <div className={`anim-bg anim-${animation}-${state}`}></div>}
    </Transition>
  );
};

export default AnimateRow;

import { FormattedMessage } from 'react-intl';

const TrackerHeader = (props) => {
  const { onChangeSort, orderBy } = props;

  const onChange = (mode) => () => {
    onChangeSort(mode);
  };

  return (
    <div
      className={`d-none d-sm-block app-table-header app-table-order-${orderBy.order}`}
    >
      <div
        className={
          'app-table-col app-table-coll-sort app-table-col-size-name' +
          (orderBy.field === 'name' ? ' active' : '')
        }
        onClick={onChange('name')}
      >
        <FormattedMessage id="app.name" />
      </div>
      <div
        className={
          'app-table-col app-table-coll-sort text-right app-table-col-size-mktcap' +
          (orderBy.field === 'marketCapUsd' ? ' active' : '')
        }
        onClick={onChange('marketCapUsd')}
      >
        <FormattedMessage id="app.market-cap" />
      </div>
      <div
        className={
          'app-table-col app-table-coll-sort text-right' +
          (orderBy.field === 'priceUsd' ? ' active' : '')
        }
        onClick={onChange('priceUsd')}
      >
        <FormattedMessage id="app.price" />
      </div>
      <div
        className={
          'app-table-col app-table-coll-sort text-right' +
          (orderBy.field === 'volumeUsd24Hr' ? ' active' : '')
        }
        onClick={onChange('volumeUsd24Hr')}
      >
        <FormattedMessage id="app.volume24" />
      </div>
      <div
        className={
          'app-table-col app-table-coll-sort text-right app-table-col-size-maxSupply' +
          (orderBy.field === 'maxSupply' ? ' active' : '')
        }
        onClick={onChange('maxSupply')}
      >
        <FormattedMessage id="app.circulating-supply" />
      </div>
      <div
        className={
          'app-table-col app-table-coll-sort text-right app-table-col-size-cap24hrChange' +
          (orderBy.field === 'changePercent24Hr' ? ' active' : '')
        }
        onClick={onChange('changePercent24Hr')}
      >
        <FormattedMessage id="app.change24" />
      </div>
      <div className="app-table-col text-right pr-0 app-table-col-size-buttons">
        <FormattedMessage id="app.trade" />
      </div>
    </div>
  );
};

export default TrackerHeader;

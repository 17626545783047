import { FormattedMessage } from 'react-intl';
import { isMobileView } from 'utils.js';
import TrackerVirtualList from './TrackerVirtualList';

const TrackerList = (props) => {
  const { list, currency } = props;
  const itemHeight = isMobileView() ? 360 : 50;
  return list && list.length ? (
    <TrackerVirtualList
      items={list}
      itemHeight={itemHeight}
      currency={currency}
    />
  ) : (
    <div className="px-2 py-3">
      <FormattedMessage id="app.list-empty" />
    </div>
  );
};

export default TrackerList;

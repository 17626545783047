import CloseIcon from '@mui/icons-material/Close';
import { ThemeContext } from 'components/theme/Theme';
import { useContext, useEffect, useState } from 'react';
import { ModalBody, Modal as ReactStrapModal } from 'reactstrap';
import './Modal.css';

const Modal = (props) => {
  const { show, onToggle, children, className } = props;
  const { theme } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const toggle = () => {
    const newState = !isOpen;
    setIsOpen(newState);

    if (typeof onToggle === 'function') {
      onToggle(newState);
    }
  };

  return (
    <ReactStrapModal
      isOpen={isOpen}
      toggle={toggle}
      className={(className ? className : '') + `theme-${theme}`}
    >
      <div className="modal-close" onClick={toggle}>
        <CloseIcon />
      </div>
      <ModalBody>{children}</ModalBody>
    </ReactStrapModal>
  );
};

export default Modal;
